import Layout from 'components/layout';
import PayView from 'components/views/pay';

const Screen = () => {
  return (
    <Layout>
      <PayView />
    </Layout>
  );
};

export default Screen;
